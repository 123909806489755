import { BREAKPOINTS, Button, COLORS, ContentIcons, DropdownFilter, InputLabel, PageWidth, Size, Textarea, TextField, ToastContext } from "@laerdal/life-react-components";
import Topics from "../../commonComponents/Topics";
import Subtopics from "../../commonComponents/Subtopics";
import Faqs from "../../commonComponents/Faqs";
import CantFindCreateCase from "../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../commonComponents/ProductTopSection";
import { countryList } from "../../model/constants/CountryList";
import { useContext, useState } from "react";
import { count } from "console";
import { FailToastOptions, SuccessToastOptions } from "../../model/constants/ToastConstants";
import CaseApi from "../../services/api/CaseApi";
import LaerdalCustomerCare from "../../commonComponents/LaerdalCustomerCare";
import posthog from 'posthog-js';

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap:32px;
    display: flex;
    align-items: start;
    
`;

const CaseContent = styled.div`
    display: flex;
    max-width: 700px;
    flex-direction: column;
    align-items: center;
    gap: 21px;
    flex-shrink: 0;
    
    align-self: center;
    align-items: start;
`;
const Title = styled.div`
    color: var(--Black, #1F1F1F);
    font-feature-settings: 'liga' off;
    /* S - Mobile/H3 */
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 38.4px */
`;
const SubTitle = styled.div`
    color: ${COLORS.neutral_700}
    font-feature-settings: 'liga' off;
    /* M - Tablet/Paragraph */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
`;


const Breadcrumb = styled.div`
  padding:12px 0;
`;
const CrumbLink = styled.a`
  &:link, &:visited, &:hover, &:active {
    color: ${COLORS.primary_600};
    text-decoration: none; 
  }

  color: ${COLORS.primary_600};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
`;
const CrumbText = styled.span`
  color: ${COLORS.neutral_500};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
`;
const Devider = styled.span`
  padding: 0 10px;
  color: ${COLORS.neutral_500};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
`;

const InputRow = styled.div`
    ${BREAKPOINTS.MEDIUM}{
        flex-direction: row;
    }
    flex-direction: column;

    display: flex;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
`;
const InputCell = styled.div`
    align-self: stretch;
    display: flex;
    padding-bottom: 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex-shrink: 0;
    flex-grow: 1;

    div {
        width: 100%;
    }

    textarea {
        width: calc(100% - 32px) !important;
        min-height:100px;
    }
`;

const CreateButton = styled(Button)`
    ${BREAKPOINTS.MEDIUM}{
        align-self: end;
    }
    align-self: stretch;
`;

const CaseCreatePage = () => {
    const {addToast} = useContext(ToastContext);

    const isValidEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    
    const [creatingcase, setCreatingcase] = useState<boolean>(false);

    const [firstName, setFirstName] = useState<string | undefined>(undefined);
    const [lastName, setLastName] = useState<string | undefined>(undefined);
    const [workEmail, setWorkEmail] = useState<string | undefined>(undefined);
    const [organization, setOrganization] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [country, setCountry] = useState<string | undefined>(undefined);    

    return (
        <>

            <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
                <PageContainer>
                    <Breadcrumb>
                        <CrumbLink href='/'>
                            Home
                        </CrumbLink>
                        <Devider>/</Devider>
                        <CrumbText>
                            Create a new case
                        </CrumbText>
                    </Breadcrumb>
                    <CaseContent>
                        <Title>Create a case?</Title>
                        <SubTitle>Share some details with us and we will tailor the best solution for you. Your information will help us offer the most relevant help.</SubTitle>

                        <InputRow>
                            <InputCell>
                                <InputLabel inputId="FirstName" text="First name" size={Size.Small} required={true}/>
                                <TextField 
                                    id="FirstName" 
                                    data-testId="FirstName" 
                                    value={firstName} 
                                    validationMessage={ firstName == undefined || firstName.length > 0 ? '' : "Required" }
                                    onChange={(x) => setFirstName(x)} 
                                    placeholder="e.g. Annie" 
                                    size={Size.Small}/>
                            </InputCell>
                            
                            <InputCell>
                                <InputLabel inputId="LastName" text="Last name" size={Size.Small} required={true}/>
                                <TextField 
                                    id="FirstNamLastName" 
                                    data-testId="LastName" 
                                    value={lastName} 
                                    validationMessage={ lastName == undefined || lastName.length > 0 ? '' : "Required" }
                                    onChange={(x) => setLastName(x)} 
                                    placeholder="e.g. Laerdal" 
                                    size={Size.Small}/>
                            </InputCell>
                        </InputRow>

                        <InputRow>
                            <InputCell>
                                <InputLabel inputId="WorkEmail" text="Work email" size={Size.Small} required={true}/>
                                <TextField 
                                    id="WorkEmail" 
                                    data-testId="WorkEmail" 
                                    value={workEmail} 
                                    validationMessage=  { workEmail == undefined ? ''
                                                            : workEmail == '' ? "Required"
                                                            : (isValidEmail(workEmail!) ? '' : 'Not an email')
                                                        }
                                    onChange={(x) => setWorkEmail(x)}
                                    placeholder="e.g. annie.laerdal@laerdal.com" 
                                    size={Size.Small}/>
                            </InputCell>
                            
                            <InputCell>
                                <InputLabel inputId="Organisation" text="Organisation" size={Size.Small} required={true}/>
                                <TextField 
                                    id="Organisation" 
                                    data-testId="Organisation" 
                                    value={organization} 
                                    validationMessage={ organization == undefined || organization.length > 0 ? '' : "Required" }
                                    onChange={(x) => setOrganization(x)} 
                                    placeholder="e.g. Laerdal Medical" 
                                    size={Size.Small}/>
                            </InputCell>
                        </InputRow>

                        <InputRow>
                            <InputCell>
                                <InputLabel inputId="Country" text="COUNTRY" size={Size.Small} required={true}/>
                                <DropdownFilter
                                    activeValidationMessage={ country == undefined || country.length > 0 ? '' : "Required" }
                                    scrollable={true}
                                    id="Country"
                                    dataTestId='CountryId'
                                    list={countryList}
                                    value={country}
                                    placeholder="-- Select an option --"
                                    onSelect={(value) => setCountry(value)}
                                    messageOnNoResults="No results found"
                                    size={Size.Medium}/>
                            </InputCell>
                        </InputRow>

                        <InputRow>
                            <InputCell>
                                <InputLabel inputId="Description" text="Description" size={Size.Small} required={true}/>
                                <Textarea 
                                    id="Description" 
                                    size={Size.Medium}
                                    data-testId="Description" 
                                    value={description} 
                                    validationMessage = { description == undefined || description.length > 0 ? '' : "Required" }
                                    onChange={(x) => setDescription(x)}
                                    placeholder="Please describe what you need assistance with" 
                                    />
                            </InputCell>
                        </InputRow>

                        <CreateButton 
                            size={Size.Large} 
                            loading={creatingcase}
                            disabled={creatingcase}
                            onClick={() => {
                                if(firstName == undefined) setFirstName('');
                                if(lastName == undefined) setLastName('');                               
                                if(workEmail == undefined) setWorkEmail('');                               
                                if(organization == undefined) setOrganization('');                               
                                if(country == undefined) setCountry('');                               
                                if(description == undefined) setDescription('');                               
                                                   
                                if( !!firstName 
                                    && !!lastName
                                    && !!workEmail
                                    && isValidEmail(workEmail!) 
                                    && !!organization
                                    && !!country
                                    && !!description
                                ){ 
                                    setCreatingcase(true);
                                    CaseApi.CreateCase({
                                        customerNumber: undefined,
                                        country: country,
                                        howCanWeHelp: 'Other',
                                        email:workEmail,
                                        subject: 'Support page - Request',
                                        description: `
                                            First name: ${firstName}\n
                                            Last name: ${lastName}\n
                                            Organization: ${organization}\n
                                            Country: ${country}\n
                                            Description: ${description}\n
                                        `
                                    }).then(()=>{
                                        
                                        posthog?.capture?.('CreateCaseModal Created', {
                                            customerNumber: undefined,
                                            country: country,
                                            howCanWeHelp: 'Other',
                                            email:workEmail,
                                            subject: 'Support page - Request',
                                            description: `
                                                First name: ${firstName}\n
                                                Last name: ${lastName}\n
                                                Organization: ${organization}\n
                                                Country: ${country}\n
                                                Description: ${description}\n
                                            `
                                        });
                                        addToast('Case created', SuccessToastOptions);
                                        setCreatingcase(false);

                                        setFirstName('');
                                        setLastName('');
                                        setWorkEmail('');
                                        setOrganization('');
                                        setDescription('');
                                        setCountry('');  
                                    }).catch(()=>{
                                        posthog?.capture?.('CreateCaseModal Error', {
                                            customerNumber: undefined,
                                            country: country,
                                            howCanWeHelp: 'Other',
                                            email:workEmail,
                                            subject: 'Support page - Request',
                                            description: `
                                                First name: ${firstName}\n
                                                Last name: ${lastName}\n
                                                Organization: ${organization}\n
                                                Country: ${country}\n
                                                Description: ${description}\n
                                            `
                                        });
                                        addToast('There was a problem creating the case', FailToastOptions);
                                        setCreatingcase(false);
                                    })
                                }
                            }}>
                            Create a case
                        </CreateButton>
                    </CaseContent>
                </PageContainer>
            </PageWidth>
            <LaerdalCustomerCare />
        </>);
};

export default CaseCreatePage;